import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// slices
import { setError } from './errors';
// utils
import axios from '../../utils/axios';
import { getValidToken } from 'src/utils/jwt';
import { formDataHeaders, headers, urls } from 'src/libs/reqConf';
import { getTeachers } from './userManage';
import { errorHandle } from 'src/utils/functions';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  myProfile: null,
  posts: [],
  users: [],
  userList: [],
  teachers: [],
  students: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET teachers
    getTeachersSuccess(state, action) {
      state.isLoading = false;
      state.teachers = action.payload;
    },

    // GET teachers
    getStudentsSuccess(state, action) {
      state.isLoading = false;
      state.students = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user?.id !== action.payload);
      state.userList = deleteUser;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------
// 청소년목록 조회
export function getStudent() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/students`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.getStudentsSuccess(response.data.data));
    } catch (error) {
      console.log('error', error.reducers);
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 청소년목록 조회
export function getTeacher() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/students`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.getTeachersSuccess(response.data.data));
    } catch (error) {
      console.log('error', error.reducers);
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function addStudent(params) {
  const { studentID, nickname, memo, teacher, mentors } = params;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/student`;
      const body = { studentID, nickname, memo, teacher, mentors };
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);
      dispatch(slice.actions.getStudentsSuccess(response.data.profile));
      dispatch(getTeachers());
    } catch (error) {
      console.log('error', error.reducers);
      dispatch(setError(errorHandle(error)));
    }
  };
}
// ----------------------------------------------------------------------

export function addTeacher(params) {
  const { teacherID, nickname, memo, profileImage, introImage } = params;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/teacher`;
      const body = {
        teacherID,
        nickname,
        memo,
        profileImage,
        introImage
      };
      const config = { headers: formDataHeaders(accessToken) };
      const response = await axios.post(url, body, config);
      dispatch(slice.actions.getTeachersSuccess(response.data.profile));
      dispatch(getTeachers());
    } catch (error) {
      console.log('error', error.reducers);
      dispatch(setError(errorHandle(error)));
    }
  };
}
// ----------------------------------------------------------------------

export function resetPassword(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/password/reset`;
      const body = params;
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);

      return response.data.data;
    } catch (error) {
      console.log('error', error.reducers);
      dispatch(setError(errorHandle(error)));
    }
  };
}
