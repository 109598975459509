// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  inflow_stats: getIcon('ic_inflow_stats'),
  schedule_manage: getIcon('ic_schedule_manage'),
  review_activity: getIcon('ic_review_activity'),
  survey_satisfaction: getIcon('ic_survey_satisfaction'),
  announcement: getIcon('ic_announcement')
};

const sidebarConfig = (t) => [
  {
    subheader: t('sideDashboard.fox.title'),
    items: [
      // MANAGEMENT : 유입통계
      {
        accessRole: [0, 1],
        title: t('sideDashboard.homepage.inflow.title'),
        path: PATH_DASHBOARD.inflow.root
      },
      {
        accessRole: [0, 1],
        title: '사용자 관리',
        path: PATH_DASHBOARD.userManage.root
      },
      {
        accessRole: [0, 1, 2],
        title: '자립 계획서',
        path: PATH_DASHBOARD.selfPlan.root
      },
      {
        accessRole: [0, 1, 2],
        title: '일정 관리',
        path: PATH_DASHBOARD.calendar.root
      },
      {
        accessRole: [0, 1, 2],
        title: '활동일지 관리',
        path: PATH_DASHBOARD.activityDiary.root
      },
      {
        accessRole: [0, 1],
        title: '설문/만족도 조사',
        path: PATH_DASHBOARD.survey.root
      },
      {
        accessRole: [0, 1],
        title: '공지사항',
        path: PATH_DASHBOARD.notice.root
      },
      {
        accessRole: [0, 1],
        title: '설정',
        path: PATH_DASHBOARD.settings.root
      }
      // // MANAGEMENT : 사용자
      // {
      // accessRole: [0,1,2],
      //   title: t('sideDashboard.homepage.user.title'),
      // path: PATH_DASHBOARD.user.account,
      // },
      // // MANAGEMENT : KPI
      // {
      // accessRole: [0,1,2],
      //   title: t('sideDashboard.homepage.kpi.title'),
      //   path: PATH_DASHBOARD.kpi.root,
      // }
    ]
  }
];

export default sidebarConfig;
