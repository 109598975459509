import _ from 'lodash';
import * as XLSX from 'xlsx';
import { fDate } from 'src/utils/formatTime';
import { boothStatus, mediaStatus, categoryStatus } from './statusCode';

// 랜덤 ObjectId
export const genObjectId = () => {
  return Array(24)
    .fill(0)
    .map(() => ((Math.random() * 16) | 0).toString(16))
    .join('');
};

// 에러 핸들
export const errorHandle = (error) => {
  console.log('error: ', error);
  let code, message, detail;

  if (isExist(error?.response?.data)) {
    const data = error?.response?.data?.response;
    code = data?.code;
    message = data?.message;
  } else {
    code = error?.response?.code;
    message = error?.response?.message;
  }

  detail = error?.errorData;
  return { code, message, detail };
};

// 값이 있는지 확인
export const isExist = (data) => {
  if (typeof data === 'string') data = data.trim();
  const emptyType = [undefined, null, NaN, false];
  const emptyString = ['undefined', 'null', 'NaN', 'false', ''];
  const emptyArray = [...emptyType, ...emptyString];

  if (typeof data === 'object') {
    return !_.isEmpty(data) ? true : false;
  } else {
    return !emptyArray.includes(data) ? true : false;
  }
};

// 값이 비어있는지 확인
export const isEmpty = (data) => {
  if (typeof data === 'string') data = data.trim();
  const emptyType = [undefined, null, NaN, false];
  const emptyString = ['undefined', 'null', 'NaN', 'false', ''];
  const emptyArray = [...emptyType, ...emptyString];

  if (typeof data === 'object') {
    return _.isEmpty(data) ? true : false;
  } else {
    return emptyArray.includes(data) ? true : false;
  }
};

export const isDate = (date) => {
  const convertedDate = new Date(date);
  return !isNaN(convertedDate);
};

// 0 제거
export const removeLeadingZero = (data = '') => {
  const result = parseInt(data, 10);
  return result.toString();
};

// 날짜별 필터링
export const mapDate = (array = []) => {
  const count = [];

  array.map((el) => {
    const date = fDate(el?.createdAt);
    const filter = count.filter((el) => isExist(el[date]));

    if (isExist(filter)) {
      count.map((el) => {
        const key = Object.keys(el)[0];
        if (key === date) {
          el[date] = el[date] + 1;
        }
      });
    } else {
      const data = { [date]: 1 };
      count.push(data);
    }
  });

  return count;
};

// KPI 데이터 정제(시작일: 2022-12-01)
export const kpiData = (array = []) => {
  const today = new Date();
  const start = { year: 2022, month: 12, day: 1 };
  const current = {
    year: today.getFullYear(),
    month: today.getMonth(),
    day: today.getDate()
  };
  const chartData = [];

  return chartData;
};

// 문자 공백 없이 붙이기
export const noSpace = (str) => {
  return str.replace(/ /g, '');
};

// 테이블 셀 데이터 포맷
export const fmDataLength = (length, data) => {
  if (isEmpty(data)) return '-';

  if (typeof data === 'string') {
    return data.length <= length ? data : data.substring(0, length) + '...';
  }

  // 데이터가 문자열이 아닌 경우 원래 값을 반환
  return data;
};

// 카테고리 노출명 생성
export const genCategoryTitle = (data = {}, length = 10) => {
  const classification = categoryStatus[data?.classification];
  const existCategory = isExist(classification);
  const title = `[${classification}] ${data?.title}`;
  const categoryTitle = existCategory ? title : null;

  return fmDataLength(length, categoryTitle);
};

// 설문 문답 질문 생성
export const genInitTextQuestion = () => ({ _id: genObjectId(), index: 0, question: '' });

// 설문 체크 질문 생성
export const checkOption = () => ({ _id: genObjectId(), label: '', checked: false });
export const genInitCheckOptions = () => [checkOption(), checkOption()];
export const genInitCheckQuestion = () => ({ ...genInitTextQuestion(), options: genInitCheckOptions() });

// 설문 선택 질문 생성
export const radioOption = () => ({ _id: genObjectId(), label: '', selected: false });
export const genInitRadioOptions = () => [radioOption(), radioOption()];
export const genInitRadioQuestion = () => ({ ...genInitTextQuestion(), options: genInitRadioOptions() });

// 데이터 업데이트
const transObjectValues = {
  category: (value) => genCategoryTitle(value, 30),
  createdAt: (value) => fDate(value),
  writeAt: (value) => fDate(value),
  startAt: (value) => fDate(value),
  endAt: (value) => fDate(value),
  comment: (value) => value?.content,
  student: (value) => value?.nickname,
  teacher: (value) => value?.nickname,
  schedule: (value) => value?.title,
  status: (value) => value + 1,
  boothType: (value) => boothStatus[value],
  mediaType: (value) => mediaStatus[value],
  classification: (value) => categoryStatus[value]
};

export const transObject = (data) => {
  const updatedData = {};

  for (const [key, value] of Object.entries(data)) {
    if (transObjectValues.hasOwnProperty(key)) {
      updatedData[key] = transObjectValues[key](value);
    } else {
      updatedData[key] = value;
    }
  }

  return updatedData;
};

// 두 날짜의 차를 시간단위로 반환
export const getTimeDifference = (startAt, endAt) => {
  const startDate = startAt instanceof Date ? startAt : new Date(startAt);
  const endDate = endAt instanceof Date ? endAt : new Date(endAt);

  let formatted = ((endDate - startDate) / (1000 * 60 * 60)).toFixed(1);

  if (formatted.endsWith('0')) {
    formatted = formatted.substring(0, formatted.length - 2);
  }

  return formatted;
};

// 엑셀 다운로드 핸들
export function downloadExcelFile(data, filename) {
  const a = document.createElement('a');
  const blob = new Blob([data], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
}

// 엑셀 다운로드 핸들 #2
export const handleDownloadExcelFile = (header = [], data = [], filename = '') => {
  const columns = [];

  // 새로운 워크북(엑셀 파일) 생성
  const workbook = XLSX.utils.book_new();

  // 시트 생성
  const sheet = XLSX.utils.aoa_to_sheet([header, ...data]);

  const maxWidth = 50;
  header.forEach((col, index) => {
    let maxColumnLength = col.length;
    data.forEach((row) => {
      if (row[index] && row[index].toString().length + 7 > maxColumnLength) {
        maxColumnLength = row[index].toString().length + 7;
      }
    });

    const adjustedWidth = Math.min(maxColumnLength, maxWidth);
    columns.push({ wch: adjustedWidth });
  });

  sheet['!cols'] = columns;

  XLSX.utils.book_append_sheet(workbook, sheet, filename);
  const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  downloadExcelFile(excelData, `${filename}.xlsx`);
};
