import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// slices
import { setError } from './errors';
// utils
import axios from '../../utils/axios';
import { getValidToken } from 'src/utils/jwt';
import { cleanObject, headers, urls } from 'src/libs/reqConf';
import { postToken } from 'src/utils/payload';
import { errorHandle } from 'src/utils/functions';

// ----------------------------------------------------------------------
// 활동일지
const initialState = {
  isLoading: false,
  journals: [],
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'journal',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET EVENTS
    getJournalSuccess(state, action) {
      console.log('action.payload', action.payload);
      state.isLoading = false;
      state.journals = action.payload;
    },

    // WRITE COMMENT
    writeCommentSuccess(state, action) {
      const newComment = action.payload;
      state.isLoading = false;
      state.journals = [...state.journals, newComment];
    },

    // UPDATE EVENT
    updateCommentSuccess(state, action) {
      state.journals = state.journals.map((_comment) => {
        return _comment._id === action.payload._id ? action.payload : _comment;
      });

      state.isLoading = false;
    },

    // DELETE EVENT
    deleteJournalSuccess(state, action) {
      const { commentId } = action.payload;
      const deleteJournal = filter(state.journals, (journal) => journal.id !== commentId);
      state.isLoading = false;
      state.journals = deleteJournal;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function handleForm(status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.handleDialog(status));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

export function getJournals() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/journals`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.getJournalSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------
// 코멘트 작성
export function writeComment(params, journalId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/journals/comment/${journalId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);
      dispatch(slice.actions.writeCommentSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function updateComment(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/journals/${id}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);
      dispatch(slice.actions.updateCommentSuccess(response.data.data));
      dispatch(getJournals());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteJournal(journalId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/journals/${journalId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(slice.actions.deleteJournalSuccess(journalId));
      dispatch(getJournals());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------
