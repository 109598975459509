import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_PAGE, PATH_DASHBOARD } from '../routes/paths';

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  const isTeacher = user?.role === 0 || user?.role === 1;
  const isMentor = user?.role === 2;

  if (isAuthenticated && isTeacher) {
    return <Navigate to={PATH_DASHBOARD.inflow.root} />;
  } else if (isAuthenticated && isMentor) {
    return <Navigate to={PATH_DASHBOARD.selfPlan.root} />;
  }

  return <>{children}</>;
}
