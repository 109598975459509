import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// utils
import { isValidToken, setSession, setSecondaryToken } from '../utils/jwt';
import { getValidToken } from 'src/utils/jwt';
// libs
import { urls, headers, jsonHeader, objectToFormData, formDataHeaders, cleanObject } from '../libs/reqConf';

const initialState = {
  isAuthenticated: false,
  secondaryAuthCode: {},
  isSecondaryAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return { ...state, isAuthenticated, isInitialized: true, user };
  },
  LOGIN: (state) => ({ ...state }),
  PROFILE: (state) => ({ ...state }),
  SECONDARY_AUTH: (state, action) => ({
    ...state,
    secondaryAuthCode: action.payload.data
  }),
  SECONDARY_AUTH_VERIFY: (state) => ({
    ...state,
    secondaryAuthCode: {}
  }),
  IS_SECONDARUY_AUTH: (state, action) => ({
    ...state,
    isSecondaryAuthenticated: action.payload
  }),
  LOGOUT: () => ({ ...initialState })
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  patchPassword: () => Promise.resolve(),
  patchProfile: () => Promise.resolve(),
  postSecondaryAuth: () => Promise.resolve(),
  postSecondaryAuthVerify: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = await getValidToken(dispatch);
        const remember = window.localStorage.getItem('remember');
        // 자동 로그인 문자열 변환(Boolean)
        const rememberCheck = (() => remember === 'true')();

        if (accessToken && rememberCheck && isValidToken(accessToken)) {
          setSession(accessToken, rememberCheck);

          const url = `${urls.user}/account/profile`;
          const config = { headers: headers(accessToken) };
          const response = await axios.get(url, config);

          const user = response.data.data;

          dispatch({ type: 'INITIALIZE', payload: { isAuthenticated: true, user } });
        } else {
          dispatch({ type: 'INITIALIZE', payload: { isAuthenticated: false, user: null } });
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: 'INITIALIZE', payload: { isAuthenticated: false, user: null } });
      }
    };

    initialize();
  }, []);

  // 로그인
  const login = async ({ teacherID, password, remember }) => {
    try {
      const url = `${urls.user}/account/login`;
      const body = { teacherID, password };
      const config = jsonHeader();
      const response = await axios.post(url, body, config);
      const { accessToken } = response.data.data;
      setSession(accessToken, remember);
      dispatch({ type: 'LOGIN' });
    } catch (error) {
      return error;
    }
  };

  // 로그아웃
  const logout = async () => {
    setSession(null, null);
    setSecondaryToken(null);
    dispatch({ type: 'LOGOUT' });
  };

  // 비밀번호 변경
  const patchPassword = async ({ oldPassword, newPassword, newPasswordConfirm }) => {
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/account/password`;
      const body = { oldPassword, newPassword, newPasswordConfirm };
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);
      setSession(null, null);
      setSecondaryToken(null);
      dispatch({ type: 'LOGOUT' });
    } catch (error) {
      console.log('error', error.response);
    }
  };

  // 프로필 조회 사용은 안하는데 일단 코드만
  const getProfile = async () => {
    const accessToken = await getValidToken(dispatch);
    const url = `${urls.user}/account/password`;
    const response = await axios.get(url);
  };

  // 프로필 수정
  const patchProfile = async (params, id) => {
    console.log('params', params);
    try {
      const token = window.localStorage.getItem('accessToken');
      const remember = window.localStorage.getItem('remember');
      const url = `${urls.user}/users/teacher/${id}`;
      const data = cleanObject(params);
      const body = objectToFormData(data);
      const config = { headers: formDataHeaders(token) };
      const response = await axios.patch(url, body, config);
      console.log('res', response.data.data);
      const { accessToken } = response.data.data;
      setSession(accessToken, remember);
      dispatch({ type: 'PROFILE' });
    } catch (error) {
      console.log('error', error);
    }
  };

  // 2차 인증코드 발송
  const postSecondaryAuth = async (params, setSubmitting) => {
    setSubmitting(true);
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.authenticate}/secondary-auth`;
      const body = params;
      const config = { headers: headers(accessToken) };

      const response = await axios.post(url, body, config);

      const data = response.data.data;

      dispatch({ type: 'SECONDARY_AUTH', payload: { data } });
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  // 2차 인증코드 검증
  const postSecondaryAuthVerify = async (params, setSubmitting) => {
    setSubmitting(true);
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.authenticate}/secondary-auth/verify`;
      const body = params;
      const config = { headers: headers(accessToken) };

      const response = await axios.post(url, body, config);

      const { secondaryToken } = response.data.data;
      setSecondaryToken(secondaryToken);

      dispatch({ type: 'SECONDARY_AUTH_VERIFY' });
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        patchPassword,
        patchProfile,
        postSecondaryAuth,
        postSecondaryAuthVerify
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
