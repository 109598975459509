import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// slices
import { setError } from './errors';
// utils
import axios from '../../utils/axios';
import { getValidToken } from 'src/utils/jwt';
import { formDataHeaders, headers, objectToFormData, urls } from 'src/libs/reqConf';
import { errorHandle } from 'src/utils/functions';

// ----------------------------------------------------------------------
// 네트워크 기관
const initialState = {
  isLoading: false,
  networkCenter: [],
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET EVENTS
    getNetworkCenterSuccess(state, action) {
      state.isLoading = false;
      state.networkCenter = action.payload;
    },

    // CREATE CATE
    addNetworkSuccess(state, action) {
      const newNetwork = action.payload;
      state.isLoading = false;
      state.networkCenter = [...state.networkCenter, newNetwork];
    },

    // UPDATE EVENT
    updateNetworkSuccess(state, action) {
      state.isLoading = false;
      state.networkCenter = state.networkCenter.map((_network) => {
        return _network._id === action.payload._id ? action.payload : _network;
      });
    },

    // DELETE EVENT
    deleteNetworkSuccess(state, action) {
      const { orgId } = action.payload;
      const deleteNetwork = filter(state.networkCenter, (network) => network.id !== orgId);
      state.isLoading = false;
      state.networkCenter = deleteNetwork;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function handleForm(status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.handleDialog(status));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

export function getNetworkCenter() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/network-organizations`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.getNetworkCenterSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function addNetwork(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/network-organizations`;
      const accessToken = await getValidToken(dispatch);
      const body = objectToFormData(params);
      const config = { headers: formDataHeaders(accessToken) };
      const response = await axios.post(url, body, config);

      dispatch(slice.actions.addNetworkSuccess(response.data.data));
      dispatch(getNetworkCenter());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function updateNetwork(orgId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/network-organizations/${orgId}`;
      const body = objectToFormData(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);
      dispatch(slice.actions.updateNetworkSuccess(response.data.data));
      dispatch(getNetworkCenter());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteNetwork(orgId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/network-organizations/${orgId}`;

      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(slice.actions.deleteNetworkSuccess(orgId));
      dispatch(getNetworkCenter());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------
