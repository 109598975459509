import axios from './axios';
import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
// libs
import { urls, headers } from 'src/libs/reqConf';
// redux
import { setError } from 'src/redux/slices/errors';
// utils
import { errorHandle } from './functions';

const isValidToken = (accessToken) => {
  if (!accessToken) return false;
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const getValidToken = async (dispatch) => {
  try {
    let accessToken = window.localStorage.getItem('accessToken');
    if (!isValidToken(accessToken)) {
      const url = `${urls.user}/account/token/refresh`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      accessToken = response?.data?.data?.accessToken;
      setAccessToken(accessToken);
    }

    return accessToken;
  } catch (error) {
    dispatch(setError(errorHandle(error)));
  }
};

const setSession = (accessToken, remember) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('remember', remember);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('remember');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setAccessToken = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  }
};

const setSecondaryToken = (secondaryToken) => {
  if (secondaryToken) {
    localStorage.setItem('secondaryToken', secondaryToken);
  } else {
    localStorage.removeItem('secondaryToken');
  }
};

export { isValidToken, getValidToken, setSession, setAccessToken, setSecondaryToken, verify, sign };
