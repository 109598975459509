import { useTranslation } from 'react-i18next';

const langs = (t) => [
  {
    value: 'kr',
    label: t('navDashboard.langs.kr'),
    icon: '/static/icons/ic_flags_kr.svg'
  }
  // {
  //   value: 'en',
  //   label: t('navDashboard.langs.en'),
  //   icon: '/static/icons/ic_flags_us.svg'
  // }
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = langs(translate).find((_lang) => _lang.value === langStorage);

  const handleChangeLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: langs(translate)
  };
}
