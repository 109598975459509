import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// slices
import { setError } from './errors';
// utils
import axios from '../../utils/axios';
import { getValidToken } from 'src/utils/jwt';
import { cleanObject, headers, urls } from 'src/libs/reqConf';
import * as payload from 'src/utils/payload';
import { errorHandle } from 'src/utils/functions';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  surveys: [],
  selectSurveyResponse: {},
  surveyAnalytics: {},
  selectedRange: null
};

const slice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // SELECT Survey Response
    selectSurveyResponseSuccess(state, action) {
      state.isLoading = false;
      state.selectSurveyResponse = action.payload;
    },

    // CREATE SURVEY
    createSurveySuccess(state, action) {
      state.isLoading = false;
    },

    // READ SURVEY
    getSurveySuccess(state, action) {
      state.isLoading = false;
      state.surveys = action.payload;
    },

    // UPDATE SURVEY
    updateSurveySuccess(state, action) {
      state.isLoading = false;
      state.surveys = state.surveys.map((_survey) => {
        return _survey._id === action.payload._id ? action.payload : _survey;
      });
    },

    // DELETE SURVEY
    deleteSurveySuccess(state, action) {
      const { surveyId } = action.payload;
      const deleteSurvey = filter(state.surveys, (survey) => survey.id !== surveyId);
      state.isLoading = false;
    },

    // READ SURVEY ANALYTICS
    getSurveyAnalyticsSuccess(state, action) {
      state.isLoading = false;
      state.surveyAnalytics = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

export function handleForm(status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.handleDialog(status));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 설문 추가
export function createSurvey(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/survey`;
      const body = cleanObject(payload.createSurvey(params));
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);

      dispatch(slice.actions.createSurveySuccess(response.data.data));
      dispatch(getSurvey());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 설문 조회
export function getSurvey() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/survey`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.getSurveySuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 설문 수정
export function updateSurvey(surveyId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/survey/${surveyId}`;
      const body = cleanObject(payload.updateSurvey(params));
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);
      dispatch(slice.actions.updateSurveySuccess(response.data.data));
      dispatch(getSurvey());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 설문 삭제
export function deleteSurvey(surveyId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/survey/${surveyId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(slice.actions.deleteSurveySuccess({ surveyId }));
      dispatch(getSurvey());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 설문 응답 조회(통계)
export function getSurveyAnalytics(surveyId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/survey/analytics/${surveyId}`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.getSurveyAnalyticsSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}
