import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PATH_PAGE } from '../routes/paths';

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.number)
};

export default function RoleBasedGuard({ children, roles }) {
  const { user } = useAuth();

  // 사용자가 로그인 하지 않았거나 필요한 권한이 없는 경우
  if (!user || !roles.includes(user?.role)) {
    return <Navigate to={PATH_PAGE.page404} />;
  }

  return <>{children}</>;
}
