import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
// images
import ilharu_logo from '../assets/brand/ilharu_logo.png';

MainLogo.propTypes = {
  sx: PropTypes.object
};

export default function MainLogo({ sx }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <img src={ilharu_logo} alt="ilharu_logo" />
    </Box>
  );
}
