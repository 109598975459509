import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// slices
import { setError } from './errors';
// utils
import axios from '../../utils/axios';
import { getValidToken } from 'src/utils/jwt';
import { cleanObject, formDataHeaders, headers, objectToFormData, urls } from 'src/libs/reqConf';
import { errorHandle } from 'src/utils/functions';

// ----------------------------------------------------------------------
// 네트워크 기관
const initialState = {
  isLoading: false,
  notices: [],
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET EVENTS
    getNoticeSuccess(state, action) {
      state.isLoading = false;
      state.notices = action.payload;
    },

    // CREATE CATE
    addNoticeSuccess(state, action) {
      const newNotice = action.payload;
      state.isLoading = false;
      state.notices = [...state.notices, newNotice];
    },

    // UPDATE EVENT
    updateNoticeSuccess(state, action) {
      state.isLoading = false;
      state.notices = state.notices.map((_notice) => {
        return _notice._id === action.payload._id ? action.payload : _notice;
      });
    },

    // DELETE EVENT
    deleteNoticeSuccess(state, action) {
      const { noticeId } = action.payload;
      const deleteNotice = filter(state.notices, (notice) => notice.id !== noticeId);
      state.isLoading = false;
      state.notices = deleteNotice;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function handleForm(status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.handleDialog(status));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

export function getNotice() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/notices`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.getNoticeSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function addNotice(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/notices`;
      const body = objectToFormData(params);
      const config = { headers: formDataHeaders(accessToken) };
      const response = await axios.post(url, body, config);
      dispatch(slice.actions.addNoticeSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function updateNotice(noticeId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/notices/${noticeId}`;
      const body = objectToFormData(params);
      const config = { headers: formDataHeaders(accessToken) };
      const response = await axios.patch(url, body, config);
      dispatch(slice.actions.updateNoticeSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteNotice(noticeId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/notices/${noticeId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(slice.actions.deleteNoticeSuccess(noticeId));
      dispatch(getNotice());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------
