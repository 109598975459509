const en = {
  translations: {
    helmet: {
      login: 'Admin | Login',
      user: { settings: 'Admin | Account' },
      pageNotFound: 'Admin | Page Not Found',
      internalServerError: 'Admin | Internal Server Error'
    },
    snackbar: {
      profileSuccess: 'Update Success',
      changePasswordSuccess: 'Change Password Success',
      createNewsSuccess: '',
      updateNewsSuccess: ''
    },
    upload: {
      remove: 'Reomve'
    },
    login: {
      welcome: 'Hi, Welcome Back',
      primary: 'Sign in Administrator',
      remember: 'Remember me',
      forgotPassword: 'Forgot password?',
      submit: 'Login',
      label: {
        email: 'Email address',
        password: 'Password'
      },
      valid: {
        email: 'Email must be a valid email address'
      },
      require: {
        email: 'Email is required',
        password: 'Password is required'
      }
    },
    user: {
      account: {
        header: {
          title: 'Account',
          name1: 'User',
          name2: 'Account Settings'
        },
        tabs: {
          general: 'General',
          changePassword: 'Change Password',
          token: 'Manage Token'
        },
        general: {
          label: {
            name: 'Name',
            email: 'Email Address',
            companyRegistrationNumber: 'companyRegistrationNumber',
            address: 'Address'
          },
          require: {
            name: 'Name is required'
          }
        },
        changePassword: {
          minimum: 'Password must be minimum 6+',
          label: {
            old: 'Old Password',
            new: 'New Password',
            confirm: 'Confirm New Password'
          },
          require: {
            old: 'Old Password is required',
            new: 'New Password is required',
            confirm: 'Confirm New Password is required'
          },
          match: {
            confirm: 'Password is not match'
          }
        },
        saveChanges: 'Save Changes'
      }
    },

    sideDashboard: {
      role: 'admin',
      homepage: {
        title: 'management',
        user: {
          title: 'user',
          account: 'account'
        }
      },
      fox: {
        title: 'Oneday Patent'
      }
    },
    navDashboard: {
      langs: {
        kr: 'Korea',
        en: 'English'
      },
      account: {
        settings: 'Settings',
        logout: 'Logout'
      }
    },
    // 404 에러
    pageNotFound: {
      primary: '',
      secondary: '',
      goToHome: ''
    },
    // 500 에러
    internalError: {
      primary: '',
      secondary: '',
      goToHome: ''
    }
  }
};

export default en;
