import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
// images
import ilharu_logo from '../assets/brand/ilharu_logo.png';

SidebarLogo.propTypes = {
  sx: PropTypes.object
};

export default function SidebarLogo({ sx, language }) {
  return (
    <Box sx={{ width: 'auto', height: 'auto', ...sx, padding: '0px 60px' }}>
      <img src={ilharu_logo} alt="tria_row_logo" width="100%" />
    </Box>
  );
}
