import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { reject } from 'lodash';
// slices
import { setError } from './errors';
// libs
import { urls, headers } from 'src/libs/reqConf';
// utils
import { getValidToken } from 'src/utils/jwt';
import * as payload from 'src/utils/payload';
import { errorHandle } from 'src/utils/functions';

const initialState = {
  isLoading: false,
  visitData: {},
  questData: {},
  externalData: [],
  kpiExternals: []
};

const slice = createSlice({
  name: 'kpi',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // POST SEARCH KPI
    postSearchKPISuccess(state, action) {
      state.isLoading = false;
      state.visitData = action.payload?.visitData;
      state.questData = action.payload?.questData;
      state.externalData = action.payload?.externals;
      state.kpiExternals = action.payload?.externals;
    },

    // POST KPI EXTERNAL
    postKpiExternalSuccess(state, action) {
      state.isLoading = false;
      state.kpi = action.payload;
      state.kpiExternals = action.payload?.externals;
    },

    // DELETE KPI EXTERNAL
    deleteKpiExternalSuccess(state, action) {
      state.isLoading = false;
      state.kpi = action.payload;
      state.kpiExternals = action.payload?.externals;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// KPI 조회
export function postSearchKPI(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.kpi}`;
      const body = payload.postSearchKPI(params);
      const config = { headers: headers(accessToken) };

      const response = await axios.post(url, body, config);

      dispatch(slice.actions.postSearchKPISuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// KPI 외부 링크 추가
export function postKpiExternal(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.kpi}/external`;
      const body = payload.postKpiExternal(params);
      const config = { headers: headers(accessToken) };

      const response = await axios.post(url, body, config);
      dispatch(slice.actions.postKpiExternalSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// KPI 외부 링크 삭제
export function deleteKpiExternal(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.kpi}/external`;
      const body = payload.deleteKpiExternal(params);
      const config = { headers: headers(accessToken), data: body };

      const response = await axios.delete(url, config);
      dispatch(slice.actions.deleteKpiExternalSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}
