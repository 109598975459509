import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { filter } from 'lodash';
// slices
import { setError } from './errors';
// libs
import { urls, headers, cleanObject, objectToFormData, formDataHeaders } from 'src/libs/reqConf';
import { getValidToken } from 'src/utils/jwt';
// utils
import { errorHandle } from 'src/utils/functions';
import * as payload from 'src/utils/payload';

const initialState = {
  isLoading: false,
  dialog: { open: false, edit: false },
  students: [],
  teachers: [],
  kpi: {},
  kpiExternals: []
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    // form handle
    handleDialog(state, action) {
      state.dialog.open = action.payload;
    },

    editDialog(state, action) {
      state.dialog.status = action.payload;
      state.dialog.edit = action.payload;
    },

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // UPDATE 청소년 수정
    updatePasswordSuccess(state, action) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

export function handleForm(status, edit) {
  return async (dispatch) => {
    try {
      if (edit) {
        dispatch(slice.actions.editDialog(status, edit));
      } else {
        dispatch(slice.actions.handleDialog(status));
      }
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 비밀번호 수정
export function updatePassword(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/account/password`;
      const body = payload.updatePassword(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);

      dispatch(slice.actions.updatePasswordSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}
