// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={user?.profileImage?.url}
      alt={user?.companyName}
      color={user?.profileImage?.url ? 'default' : createAvatar(user?.companyName).color}
      {...other}
    >
      {createAvatar(user?.companyName).name}
    </MAvatar>
  );
}
