const { REACT_APP_API_URL_V1, REACT_APP_API_URL_V2 } = process.env;

const host_v1 = REACT_APP_API_URL_V1;
const host_v2 = REACT_APP_API_URL_V2;

export const urls = {
  user: host_v2 + '/teachers',
  token: host_v2 + '/token',
  kpi: host_v1 + '/kpi'
};

// 일반 헤더
export const jsonHeader = () => {
  return {
    'Content-Type': 'application/json'
  };
};

// jwt 헤더
export const headers = (token) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
};

// 파일 업로드 헤더
export const formDataHeaders = (token) => {
  return {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`
  };
};

// 객체 정리
export const cleanObject = (object = {}) => {
  const cleanElements = [null, undefined, 'null', 'undefined'];
  const cleanObject = { ...object };

  // 문자열 좌우 공백 제거
  Object.keys(cleanObject).forEach((key) => {
    if (typeof cleanObject[key] === 'string') {
      cleanObject[key] = cleanObject[key].trim();
    }
  });

  // 불필요 속성 제거(ex, null, undefined)
  const propNames = Object.getOwnPropertyNames(cleanObject);
  for (let i = 0; i < propNames.length; i++) {
    const propName = propNames[i];
    if (cleanElements.includes(cleanObject[propName])) {
      delete cleanObject[propName];
    }
  }

  return cleanObject;
};

// 파일 업로드 폼데이터 변환
export const objectToFormData = (object) => {
  const formData = new FormData();

  for (let key in object) {
    formData.append(key, object[key]);
  }

  return formData;
};
