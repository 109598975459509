import moment from 'moment';
import { format, formatDistanceToNow } from 'date-fns';

export function fMonth(date) {
  return date ? format(new Date(date), 'yyyy-MM') : '';
}

export function fDate(date) {
  return date ? format(new Date(date), 'yyyy-MM-dd') : '';
}

export function fDateTime(date) {
  return date ? format(new Date(date), 'yyyy-MM-dd HH:mm') : '';
}

export function fDateTimeSuffix(date) {
  return date ? format(new Date(date), 'yyyy/MM/dd hh:mm p') : '';
}

export function fDateTimeKorea(date) {
  if (date) {
    const formattedDate = moment(date).format('YYYY년 M월 D일 A h시');
    return formattedDate.replace('AM', '오전').replace('PM', '오후');
  }
  return '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true
      })
    : '';
}
