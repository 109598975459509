import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// slices
import { setError } from './errors';
// utils
import axios from '../../utils/axios';
import { getValidToken } from 'src/utils/jwt';
import { cleanObject, headers, urls } from 'src/libs/reqConf';
import { errorHandle } from 'src/utils/functions';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  events: [],
  scheduleHistory: [],
  isOpenModal: false,
  dialog: false,
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    handleDialog(state, action) {
      state.dialog = action.payload;
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET scheduleHistory
    getScheduleHistorySuccess(state, action) {
      state.scheduleHistory = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      const newArr = action.payload.map((el, idx) => ({
        ...el,
        start: el.startDate,
        end: el.endDate
      }));

      const extractedReview = action.payload?.map((el) => el.review);
      state.isLoading = false;
      state.events = newArr;
      state.reviews = extractedReview;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      state.isLoading = false;
      state.events = state.events.map((_event) => {
        return _event._id === action.payload._id ? action.payload : _event;
      });
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = filter(state.events, (user) => user?.id !== eventId);
      state.isLoading = false;
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { startDate, endDate } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { startDate, endDate };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function handleForm(status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.handleDialog(status));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------
export function getScheduleHistory() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/schedules/list`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.getScheduleHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

export function getEvents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/schedules`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.getEventsSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/schedules`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);
      dispatch(slice.actions.createEventSuccess(response.data.data));
      dispatch(getEvents());
      dispatch(closeModal());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}
// ----------------------------------------------------------------------

export function updateEvent(scheduleId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/schedules/${scheduleId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);
      dispatch(slice.actions.updateEventSuccess(response.data.data));
      dispatch(getEvents());
      dispatch(closeModal());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteEvent(scheduleId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/schedules/${scheduleId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(slice.actions.deleteEventSuccess(scheduleId));
      dispatch(getEvents());
      dispatch(closeModal());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}
// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async (dispatch) => {
    dispatch(
      slice.actions.selectRange({
        startDate: start.getTime(),
        endDate: end.getTime()
      })
    );
  };
}
