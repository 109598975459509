export const preferredServiceStatus = {
  workplaceExperience: '직장 체험',
  careerJobExperience: '진로 직업 체험',
  mentoring: '멘토링',
  jobTraining: '직업 훈련',
  independenceSkillTraining: '자립 기술 훈련',
  certificationAcquisition: '자격증 취득'
};

export const scheduleStatus = {
  active: '활성',
  inactive: '비활성',
  delete: '삭제',
  pending: '대기중',
  progress: '진행중',
  complete: '일정 종료'
};

export const reviewStatus = {
  pending: '대기중',
  progress: '진행중',
  needReview: '소감 작성필요',
  complete: '일정 종료'
};

export const boothStatus = {
  market: '마켓',
  cafe: '카페',
  handmade: '핸드메이드',
  mediaStudio: '비디오 스튜디오',
  activity: '활동',
  live: '라이브',
  exhibition: '전시'
};

export const mediaStatus = {
  video: '영상',
  image: '이미지',
  product: '상품'
};

export const teacherStatus = {
  active: '활성',
  inactive: '비활성',
  delete: '삭제'
};

export const studentStatus = {
  active: '활성',
  inactive: '비활성',
  delete: '삭제'
};

export const studentLevelStatus = {
  1: '1',
  2: '2',
  3: '3',
  10: '완료'
};

export const categoryStatus = {
  root: '최고 권한',
  student: '청소년',
  teacher: '선생님',
  schedule: '일정',
  survey: '설문'
};
