import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// slices
import { setError } from './errors';
// utils
import axios from '../../utils/axios';
import { getValidToken } from 'src/utils/jwt';
import { cleanObject, headers, urls } from 'src/libs/reqConf';
import { errorHandle } from 'src/utils/functions';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  selfPlans: [],
  selectSelfPlan: {},
  isOpenModal: false,
  dialog: false,
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'selfPlan',
  initialState,
  reducers: {
    handleDialog(state, action) {
      state.dialog = action.payload;
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET EVENTS
    getSelfPlanSuccess(state, action) {
      state.isLoading = false;
      state.selfPlans = action.payload;
    },

    // CREATE EVENT
    createSelfPlanSuccess(state, action) {
      const newPlan = action.payload;
      state.isLoading = false;
      state.selfPlans = [...state.selfPlans, newPlan];
    },

    selectSelfPlanSuccess(state, action) {
      state.isLoading = false;
      state.selectSelfPlan = action.payload;
    },

    updateSelfPlanSuccess(state, action) {
      state.isLoading = false;
      state.selfPlans = state.selfPlans.map((_selfPlan) => {
        return _selfPlan._id === action.payload._id ? action.payload : _selfPlan;
      });
    },

    // DELETE EVENT
    deleteSelfPlanSuccess(state, action) {
      const { planId } = action.payload;
      const deleteSelfPlan = filter(state.selfPlans, (plan) => plan.id !== planId);
      state.isLoading = false;
      state.selfPlans = deleteSelfPlan;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const planId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = planId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModSelfPlan, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function handleForm(status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.handleDialog(status));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

export function getSelfPlans() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/independence-plans`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.getSelfPlanSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function selectSelfPlanSuccess(plandId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/independence-plans/${plandId}`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.selectSelfPlanSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------
// 코멘트작성이 자립계획서 update라고 생각하면된다.
export function createComment(planId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/independence-plans/comment/${planId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);
      dispatch(slice.actions.updateSelfPlanSuccess(response.data.data));
      dispatch(getSelfPlans());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}
// ----------------------------------------------------------------------

export function updateSelfPlan(planId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/independence-plans/${planId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);
      dispatch(slice.actions.updateSelfPlanSuccess(response.data.data));
      dispatch(getSelfPlans());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteSelfPlan(planId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/independence-plans/${planId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(slice.actions.deleteSelfPlanSuccess(planId));
      dispatch(getSelfPlans());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}
// ----------------------------------------------------------------------

export function selectSelfPlan(start, end) {
  return async (dispatch) => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };
}
