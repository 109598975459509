import { cleanObject } from 'src/libs/reqConf';

/* 토큰 */
// 토큰 생성
export const postToken = (object) => {
  return cleanObject({
    tokenName: object?.tokenName,
    expiresIn: object?.expiresIn,
    kpi: object?.kpi,
    smti: object?.smti
  });
};

// 토큰 삭제
export const deleteToken = (object) => {
  return cleanObject({
    tokenId: object?.tokenId
  });
};

/* KPI */
// KPI 조회
export const postSearchKPI = (object) => {
  return cleanObject({
    startDate: object?.startDate,
    endDate: object?.endDate
  });
};

// KPI 외부 링크 추가
export const postKpiExternal = (object) => {
  return cleanObject({
    domainName: object?.domainName,
    url: object?.url
  });
};

// KPI 외부 링크 삭제
export const deleteKpiExternal = (object) => {
  return cleanObject({
    kpiExternalId: object?.kpiExternalId
  });
};

/* Account */
// 비밀번호 수정
export const updatePassword = (object) => {
  return cleanObject({
    oldPassword: object?.oldPassword,
    newPassword: object?.newPassword,
    newPasswordConfirm: object?.newPasswordConfirm
  });
};

/* Booth */
// 부스별 조회
export const searchByBooth = (object) => {
  return cleanObject({
    boothType: object?.boothType
  });
};

// 부스 영상 추가
export const createBoothVideo = (object) => {
  return cleanObject({
    boothType: object?.boothType,
    mediaType: object?.mediaType,
    order: object?.order,
    memo: object?.memo,
    url: object?.url
  });
};

// 부스 영상 수정
export const updateBoothVideo = (object) => {
  return cleanObject({
    boothType: object?.boothType,
    order: object?.order,
    memo: object?.memo,
    url: object?.url
  });
};

// 부스 이미지 추가
export const createBoothImage = (object) => {
  return cleanObject({
    boothType: object?.boothType,
    mediaType: object?.mediaType,
    order: object?.order,
    memo: object?.memo,
    image: object?.image
  });
};

// 부스 이미지 수정
export const updateBoothImage = (object) => {
  return cleanObject({
    boothType: object?.boothType,
    order: object?.order,
    memo: object?.memo,
    image: object?.image
  });
};

// 부스 상품 추가
export const createBoothProduct = (object) => {
  return cleanObject({
    boothType: object?.boothType,
    mediaType: object?.mediaType,
    order: object?.order,
    memo: object?.memo,
    url: object?.url,
    image: object?.image
  });
};

// 부스 상품 수정
export const updateBoothProduct = (object) => {
  return cleanObject({
    boothType: object?.boothType,
    order: object?.order,
    memo: object?.memo,
    url: object?.url,
    image: object?.image
  });
};

/* Surveys */
// 설문 추가
export const createSurvey = (object) => {
  return cleanObject({
    category: object?.category,
    title: object?.title,
    details: object?.details,
    texts: object?.texts,
    checks: object?.checks,
    radios: object?.radios,
    startAt: object?.startAt,
    endAt: object?.endAt
  });
};

// 설문 수정
export const updateSurvey = (object) => {
  return cleanObject({
    category: object?.category,
    title: object?.title,
    details: object?.details,
    texts: object?.texts,
    checks: object?.checks,
    radios: object?.radios,
    startAt: object?.startAt,
    endAt: object?.endAt
  });
};
