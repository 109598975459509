import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { path: '/', element: <Navigate to="/login" replace /> },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: <Navigate to="/dashboard/inflow" replace />
        },
        {
          path: 'user-manage',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <UserManage />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'user',
          children: [
            {
              path: 'account',
              element: (
                <RoleBasedGuard roles={[0, 1, 2]}>
                  <UserAccount />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'kpi',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <KPI />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'inflow',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Inflow />
                  {/* <KPI /> */}
                </RoleBasedGuard>
              )
            }
          ]
        },

        {
          path: 'calendar',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1, 2]}>
                  <Calendar />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'activityDiary',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1, 2]}>
                  <ActivityDiary />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'survey',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Survey />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'settings',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Settings />
                </RoleBasedGuard>
              )
            },
            {
              path: '/booth',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Settings />
                </RoleBasedGuard>
              )
            },
            {
              path: '/network',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Settings />
                </RoleBasedGuard>
              )
            },
            {
              path: '/category',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Settings />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'notice',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1]}>
                  <Notice />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'self-plan',
          children: [
            {
              path: '/',
              element: (
                <RoleBasedGuard roles={[0, 1, 2]}>
                  <SelfPlan />
                </RoleBasedGuard>
              )
            }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const UserManage = Loadable(lazy(() => import('../pages/dashboard/UserManage')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const KPI = Loadable(lazy(() => import('../pages/dashboard/kpi/KPI')));
const Inflow = Loadable(lazy(() => import('../pages/dashboard/Inflow')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const ActivityDiary = Loadable(lazy(() => import('../pages/dashboard/ActivityDiary')));
const Survey = Loadable(lazy(() => import('../pages/dashboard/Survey')));
const Settings = Loadable(lazy(() => import('../pages/dashboard/Settings')));
const Notice = Loadable(lazy(() => import('../pages/dashboard/Notice')));
const SelfPlan = Loadable(lazy(() => import('../pages/dashboard/SelfPlan')));
// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
