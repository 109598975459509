import { useState } from 'react';
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@material-ui/core';
import { MButton } from '../../../../components/@material-extend';
import { Icon } from '@iconify/react';
// utils
import { isExist } from 'src/utils/functions';

export default function FormDialogs({ error, open, onClose }) {
  const code = error?.code;
  const message = error?.message;
  const detail = error?.detail;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={false}
        fullWidth={true}
        PaperProps={{ style: { maxWidth: '500px' } }}
      >
        {/* 제목 */}
        <DialogTitle>오류 내용</DialogTitle>

        {/* 내용 */}
        <DialogContent style={{ textAlign: 'center' }}>
          {isExist(code) && <DialogContentText>{code}</DialogContentText>}
          {isExist(message) && <DialogContentText>{message}</DialogContentText>}
          {isExist(detail) && <DialogContentText>{detail}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
