import { filter, reject, remove } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
// slices
import { setError } from './errors';
// utils
import axios from 'src/utils/axios';
import { getValidToken } from 'src/utils/jwt';
import { formDataHeaders, headers, objectToFormData, urls } from 'src/libs/reqConf';
import { errorHandle } from 'src/utils/functions';
import * as payload from 'src/utils/payload';

// 가상공간 관리(부스)
const initialState = {
  isLoading: false,
  boothDatas: [],
  boothData: {},
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // READ BOOTH ALL
    readAllBoothSuccess(state, action) {
      state.isLoading = false;
      state.boothDatas = action.payload;
    },

    // POST SEARCH BOOTH
    searchByBoothSuccess(state, action) {
      state.isLoading = false;
      state.boothData = action.payload;
    },

    // CREATE BOOTH VIDEO
    createBoothVideoSuccess(state, action) {
      state.isLoading = false;
    },

    // READ BOOTH VIDEO
    readBoothVideoSuccess(state, action) {
      state.isLoading = false;
      state.boothData = action.payload;
    },

    // UPDATE BOOTH VIDEO
    updateBoothVideoSuccess(state, action) {
      state.isLoading = false;
    },

    // DELETE BOOTH VIDEO
    deleteBoothVideoSuccess(state, action) {
      const removeId = { _id: action.payload };
      state.boothData.media = reject(state.boothData.media, removeId);
      state.isLoading = false;
    },

    // CREATE BOOTH IMAGE
    createBoothImageSuccess(state, action) {
      state.isLoading = false;
    },

    // READ BOOTH IMAGE
    readBoothImageSuccess(state, action) {
      state.isLoading = false;
      state.boothData = action.payload;
    },

    // UPDATE BOOTH IMAGE
    updateBoothImageSuccess(state, action) {
      state.isLoading = false;
    },

    // DELETE BOOTH IMAGE
    deleteBoothImageSuccess(state, action) {
      const removeId = { _id: action.payload };
      state.boothData.media = reject(state.boothData.media, removeId);
      state.isLoading = false;
    },

    // CREATE BOOTH PRODUCT
    createBoothProductSuccess(state, action) {
      state.isLoading = false;
    },

    // READ BOOTH PRODUCT
    readBoothProductSuccess(state, action) {
      state.isLoading = false;
      state.boothData = action.payload;
    },

    // UPDATE BOOTH PRODUCT
    updateBoothProductSuccess(state, action) {
      state.isLoading = false;
    },

    // DELETE BOOTH PRODUCT
    deleteBoothProductSuccess(state, action) {
      const removeId = { _id: action.payload };
      state.boothData.media = reject(state.boothData.media, removeId);
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

export function handleForm(status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.handleDialog(status));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 가상공간 관리 전체 조회
export function readAllBooth() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/virtual-spaces`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.readAllBoothSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스별 조회
export function searchByBooth(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/virtual-spaces/booth`;
      const accessToken = await getValidToken(dispatch);
      const body = payload.searchByBooth(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);

      dispatch(slice.actions.searchByBoothSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스 영상 추가
export function createBoothVideo(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/virtual-spaces/video`;
      const accessToken = await getValidToken(dispatch);
      const body = payload.createBoothVideo(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);

      dispatch(slice.actions.createBoothVideoSuccess(response.data.data));
      dispatch(searchByBooth({ boothType: params?.boothType }));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스 영상 조회
export function readBoothVideo() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/virtual-spaces/videos`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.readBoothVideoSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스 영상 수정
export function updateBoothVideo(videoId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/virtual-spaces/video/${videoId}`;
      const body = payload.updateBoothVideo(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);

      dispatch(slice.actions.updateBoothVideoSuccess(response.data.data));
      dispatch(searchByBooth({ boothType: params?.boothType }));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스 영상 삭제
export function deleteBoothVideo(videoId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/virtual-spaces/video/${videoId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);

      dispatch(slice.actions.deleteBoothVideoSuccess(videoId));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스 이미지 추가
export function createBoothImage(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/virtual-spaces/image`;
      const accessToken = await getValidToken(dispatch);
      const body = objectToFormData(payload.createBoothImage(params));
      const config = { headers: formDataHeaders(accessToken) };
      const response = await axios.post(url, body, config);

      dispatch(slice.actions.createBoothImageSuccess(response.data.data));
      dispatch(searchByBooth({ boothType: params?.boothType }));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스 이미지 조회
export function readBoothImage() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/virtual-spaces/images`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.readBoothImageSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스 이미지 수정
export function updateBoothImage(ids, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { mediaId, imageId } = ids;
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/virtual-spaces/image/${mediaId}/${imageId}`;
      const body = objectToFormData(payload.updateBoothImage(params));
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);

      dispatch(slice.actions.updateBoothImageSuccess(response.data.data));
      dispatch(searchByBooth({ boothType: params?.boothType }));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스 이미지 삭제
export function deleteBoothImage(ids, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { mediaId, imageId } = ids;
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/virtual-spaces/image/${mediaId}/${imageId}`;
      const config = { headers: headers(accessToken), data: params };
      const response = await axios.delete(url, config);
      const deleteId = response?.data?.data?._id;

      dispatch(slice.actions.deleteBoothImageSuccess(deleteId));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스 상품 추가
export function createBoothProduct(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/virtual-spaces/product`;
      const accessToken = await getValidToken(dispatch);
      const body = objectToFormData(payload.createBoothProduct(params));
      const config = { headers: formDataHeaders(accessToken) };
      const response = await axios.post(url, body, config);

      dispatch(slice.actions.createBoothProductSuccess(response.data.data));
      dispatch(searchByBooth({ boothType: params?.boothType }));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스 상품 조회
export function readBoothProduct() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/virtual-spaces/products`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.readBoothProductSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스 상품 수정
export function updateBoothProduct(productId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/virtual-spaces/product/${productId}`;
      const body = objectToFormData(payload.updateBoothProduct(params));
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);

      dispatch(slice.actions.updateBoothProductSuccess(response.data.data));
      dispatch(searchByBooth({ boothType: params?.boothType }));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 부스 상품 삭제
export function deleteBoothProduct(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/virtual-spaces/product/${productId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);

      dispatch(slice.actions.deleteBoothProductSuccess(productId));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}
