import * as Yup from 'yup';
import i18n from 'src/locales/i18n';

// 로그인 스키마
export const loginSchema = Yup.object().shape({
  teacherID: Yup.string().required(i18n.t('login.require.id')),
  password: Yup.string().required(i18n.t('login.require.password'))
});

// 비밀번호 스키마
export const passwordChangeSchema = Yup.object().shape({
  oldPassword: Yup.string().required(i18n.t('user.account.changePassword.require.old')),
  newPassword: Yup.string()
    .min(6, i18n.t('user.account.changePassword.minimum'))
    .required(i18n.t('user.account.changePassword.require.new')),
  newPasswordConfirm: Yup.string()
    .required(i18n.t('user.account.changePassword.require.confirm'))
    .oneOf([Yup.ref('newPassword'), null], i18n.t('user.account.changePassword.match.confirm'))
});

// 청소년 스키마
export const studentSchema = Yup.object().shape({
  studentID: Yup.string().required(i18n.t('dialogs.student.require.studentID')),
  teacherID: Yup.string().required(i18n.t('dialogs.student.require.teacherID')),
  approvedLevel: Yup.string().required(i18n.t('dialogs.student.require.approvedLevel')),
  category: Yup.string().required(i18n.t('dialogs.student.require.category')),
  nickname: Yup.string(),
  memo: Yup.string()
});

// 선생님 스키마
export const teacherSchema = Yup.object().shape({
  category: Yup.string().required(i18n.t('dialogs.teacher.require.category')),
  teacherID: Yup.string().required(i18n.t('dialogs.teacher.require.teacherID')),
  nickname: Yup.string(),
  memo: Yup.string(),
  teacherAbout: Yup.string(),
  introImage: Yup.object().nullable(),
  profileImage: Yup.object().nullable()
});

// 자립계획서 스키마
export const selfPlanSchema = Yup.object().shape({
  goal: Yup.string().required(i18n.t('dialogs.selfplan.require.goal')),
  comment: Yup.string().required(i18n.t('dialogs.selfplan.require.comment')),
  detailedPlan: Yup.string().required(i18n.t('dialogs.selfplan.require.detailedPlan')),
  preferredService: Yup.array().required(i18n.t('dialogs.selfplan.require.detailedPlan'))
});

// 일정 스키마
export const calendarSchema = Yup.object().shape({
  title: Yup.string().max(255).required(i18n.t('dialogs.calendar.require.title')),
  category: Yup.string().required(i18n.t('dialogs.calendar.require.category')),
  totalCompletionTime: Yup.number().required(i18n.t('dialogs.calendar.require.totalCompletionTime')),
  cronDaysOfWeek: Yup.array().required(i18n.t('dialogs.calendar.require.cronDaysOfWeek')),
  description: Yup.string().max(5000).required(i18n.t('dialogs.calendar.require.description')),
  end: Yup.date().when(
    'start',
    (start, schema) =>
      start && schema.min(start, '날짜를 선택해주세요.').required(i18n.t('dialogs.calendar.require.end'))
  ),
  start: Yup.date().required(i18n.t('dialogs.calendar.require.start')),
  mentor: Yup.string().required(i18n.t('dialogs.calendar.require.mentor')),
  students: Yup.array().of(Yup.string()).required(i18n.t('dialogs.calendar.require.students'))
});

// 소감/후기(리뷰) 작성 스키마
export const reviewSchema = Yup.object().shape({
  content: Yup.string().required(i18n.t('dialogs.review.require.content')),
  comment: Yup.string().required(i18n.t('dialogs.review.require.comment'))
});

// 활동일지 스키마
export const diarySchema = Yup.object().shape({
  content: Yup.string().required(i18n.t('dialogs.diary.require.content')),
  feelings: Yup.string().required(i18n.t('dialogs.diary.require.feelings')),
  comment: Yup.string().required(i18n.t('dialogs.diary.require.comment'))
});

// 설문 스키마
export const surveySchema = Yup.object().shape({
  category: Yup.string().required(i18n.t('dialogs.survey.require.category')),
  title: Yup.string().required(i18n.t('dialogs.survey.require.title')),
  startAt: Yup.string().required(i18n.t('dialogs.survey.require.startAt')),
  endAt: Yup.string().required(i18n.t('dialogs.survey.require.endAt')),
  details: Yup.string(),
  texts: Yup.array()
    .notRequired()
    .of(
      Yup.object().shape({
        index: Yup.number().required(i18n.t('dialogs.survey.require.index')),
        question: Yup.string().required(i18n.t('dialogs.survey.require.question'))
      })
    ),
  checks: Yup.array()
    .notRequired()
    .of(
      Yup.object().shape({
        index: Yup.number().required(i18n.t('dialogs.survey.require.index')),
        question: Yup.string().required(i18n.t('dialogs.survey.require.question'))
      })
    ),
  radios: Yup.array()
    .notRequired()
    .of(
      Yup.object().shape({
        index: Yup.number().required(i18n.t('dialogs.survey.require.index')),
        question: Yup.string().required(i18n.t('dialogs.survey.require.question'))
      })
    )
});

// 부스 스키마
export const boothSchema = Yup.object().shape({
  boothType: Yup.string().required(i18n.t('dialogs.booth.require.boothType')),
  mediaType: Yup.string().required(i18n.t('dialogs.booth.require.mediaType')),
  order: Yup.number()
    .min(1, i18n.t('dialogs.booth.require.positiveOrder'))
    .required(i18n.t('dialogs.booth.require.order')),
  memo: Yup.string(),
  url: Yup.string(),
  image: Yup.object().nullable()
});

// 공지사항 스키마
export const noticeSchema = Yup.object().shape({
  title: Yup.string().required(i18n.t('dialogs.require.title')),
  content: Yup.string().required(i18n.t('dialogs.require.content')),
  noticeImage: Yup.object().nullable()
});

// 네트워크 기관 스키마
export const networkOrgSchema = Yup.object().shape({
  organizationName: Yup.string().required(i18n.t('dialogs.network.require.organizationName')),
  subtitle: Yup.string(),
  description: Yup.string(),
  contactName: Yup.string(),
  contactNumber: Yup.number(),
  introImage: Yup.object().nullable()
});

// 카테고리 스키마
export const categorySchema = Yup.object().shape({
  classification: Yup.string().required(i18n.t('dialogs.category.require.classification')),
  title: Yup.string().required(i18n.t('dialogs.category.require.title')),
  description: Yup.string()
});
