import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { filter } from 'lodash';
// slices
import { setError } from './errors';
// libs
import { urls, headers, cleanObject, objectToFormData, formDataHeaders } from 'src/libs/reqConf';
// utils
import { errorHandle } from 'src/utils/functions';
import { getValidToken } from 'src/utils/jwt';

const initialState = {
  isLoading: false,
  dialog: { open: false, edit: false },
  students: [],
  teachers: [],
  kpi: {},
  kpiExternals: []
};

const slice = createSlice({
  name: 'userManage',
  initialState,
  reducers: {
    // form handle
    handleDialog(state, action) {
      state.dialog.open = action.payload;
    },

    editDialog(state, action) {
      state.dialog.status = action.payload;
      state.dialog.edit = action.payload;
    },

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET 청소년조회
    getStudentsSuccess(state, action) {
      state.isLoading = false;
      state.students = action.payload;
    },

    // POST 청소년추가
    addStudentSuccess(state, action) {
      state.isLoading = false;
    },

    // UPDATE 청소년 수정
    updateStudentSuccess(state, action) {
      state.isLoading = false;
    },

    // DELETE 청소년
    deleteStudentSuccess(state, action) {
      state.isLoading = false;
    },
    //-----------------------------------------------------------------
    // GET 선생님조회
    getTeachersSuccess(state, action) {
      state.isLoading = false;
      state.teachers = action.payload;
    },
    // POST 청소년추가
    addTeachersSuccess(state, action) {
      state.isLoading = false;
    },
    // UPDATE 청소년 수정
    updateTeacherSuccess(state, action) {
      state.isLoading = false;
    },
    // DELETE 청소년
    deleteTeacherSuccess(state, action) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

export function handleForm(status, edit) {
  return async (dispatch) => {
    try {
      if (edit) {
        dispatch(slice.actions.editDialog(status, edit));
      } else {
        dispatch(slice.actions.handleDialog(status));
      }
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 청소년 조회
export function getStudents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/students`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.getStudentsSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 청소년 추가
export function addStudent(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/student`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);
      dispatch(slice.actions.addStudentSuccess(response.data.data));
      dispatch(getStudents());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 청소년 정보 수정
export function updateStudent(studentId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/student/${studentId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);
      dispatch(slice.actions.updateStudentSuccess(response.data.data));
      dispatch(getStudents());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 청소년 삭제
export function deleteStudent(studentId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/student/${studentId}`;
      const config = { headers: headers(accessToken) };
      const response = await axios.delete(url, config);
      dispatch(slice.actions.deleteStudentSuccess(response.data.data));
      dispatch(getStudents());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 선생님 조회
export function getTeachers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/teachers`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.getTeachersSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 선생님 추가
export function addTeacher(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/teacher`;
      const body = objectToFormData(params);
      const config = { headers: formDataHeaders(accessToken) };
      const response = await axios.post(url, body, config);
      dispatch(slice.actions.addTeachersSuccess(response.data.data));
      dispatch(getTeachers());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 선생님 정보 수정
export function updateTeacher(teacherId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/teacher/${teacherId}`;
      const body = objectToFormData(params);
      const config = { headers: formDataHeaders(accessToken) };
      const response = await axios.patch(url, body, config);

      dispatch(slice.actions.updateTeacherSuccess(response.data.data));
      dispatch(getTeachers());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// 청소년 삭제
export function deleteTeacher(teacherId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/users/teacher/${teacherId}`;
      const config = { headers: headers(accessToken) };
      const response = await axios.delete(url, config);
      dispatch(slice.actions.deleteTeacherSuccess(response.data.data));
      dispatch(getTeachers());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}
