import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
// icons
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// utils
import { loginSchema } from 'src/utils/validationSchemas';
import { isExist } from 'src/utils/functions';

export default function LoginForm() {
  const { t } = useTranslation();
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: loginSchema,
    initialValues: {
      teacherID: '',
      password: '',
      remember: true
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const response = await login(values);

        if (isExist(response?.isAxiosError)) {
          throw response;
        } else {
          window.location.reload();
        }

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        const { response } = error;
        const afterSubmit = response.data.response.message;
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete="username"
            type="teacherID"
            label={t('login.label.id')}
            {...getFieldProps('teacherID')}
            error={Boolean(touched.id && errors.id)}
            helperText={touched.id && errors.id}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={t('login.label.password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* 자동 로그인 기억하기 */}
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label={t('login.remember')}
          />
        </Stack>

        {/* 로그인 버튼 */}
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('login.submit')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
