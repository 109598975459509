import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// slices
import { setError } from './errors';
// utils
import axios from '../../utils/axios';
import { getValidToken } from 'src/utils/jwt';
import { cleanObject, headers, urls } from 'src/libs/reqConf';
import { errorHandle } from 'src/utils/functions';
import { getScheduleHistory } from './calendar';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  reviews: [],
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET EVENTS
    getReviewSuccess(state, action) {
      state.isLoading = false;
      state.reviews = action.payload;
    },

    // CREATE CATE
    writeCommentSuccess(state, action) {
      const newComment = action.payload;
      state.isLoading = false;
      state.reviews = [...state.reviews, newComment];
    },

    // UPDATE EVENT
    updateCommentSuccess(state, action) {
      state.isLoading = false;
      state.reviews = state.reviews.map((_comment) => {
        return _comment._id === action.payload._id ? action.payload : _comment;
      });
    },

    // DELETE EVENT
    deleteReviewSuccess(state, action) {
      const { commentId } = action.payload;
      const deleteReview = filter(state.reviews, (category) => category.id !== commentId);
      state.isLoading = false;
      state.events = deleteReview;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function handleForm(status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.handleDialog(status));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

export function getReviews() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/review`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.getReviewSuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------
// 코멘트 작성
export function writeComment(params, reviewId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/review/comment/${reviewId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);
      dispatch(slice.actions.writeCommentSuccess(response.data.data));
      dispatch(getScheduleHistory());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function updateComment(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/review/${id}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);
      dispatch(slice.actions.updateCommentSuccess(response.data.data));
      dispatch(getScheduleHistory());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteReview(reviewId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/review/${reviewId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(slice.actions.deleteReviewSuccess({ reviewId }));
      dispatch(getScheduleHistory());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------
