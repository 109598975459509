import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// slices
import { setError } from './errors';
// utils
import axios from '../../utils/axios';
import { getValidToken } from 'src/utils/jwt';
import { cleanObject, headers, urls } from 'src/libs/reqConf';
import { postToken } from 'src/utils/payload';
import { errorHandle } from 'src/utils/functions';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  categories: [],
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET EVENTS
    getCategorySuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // CREATE CATE
    addCategorySuccess(state, action) {
      const newCategory = action.payload;
      state.isLoading = false;
      state.categories = [...state.categories, newCategory];
    },

    // UPDATE EVENT
    updateCategorySuccess(state, action) {
      state.isLoading = false;
      state.categories = state.categories.map((_category) => {
        return _category._id === action.payload._id ? action.payload : _category;
      });
    },

    // DELETE EVENT
    deleteCategorySuccess(state, action) {
      const { categoryId } = action.payload;
      const deleteCategory = filter(state.categories, (category) => category._id !== categoryId);
      state.isLoading = false;
      state.categories = deleteCategory;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function handleForm(status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.handleDialog(status));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

export function getCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `${urls.user}/categories`;
      const accessToken = await getValidToken(dispatch);
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.getCategorySuccess(response.data.data));
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function addCategory(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/categories`;
      const body = cleanObject(params);

      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);

      dispatch(slice.actions.addCategorySuccess(response.data.data));
      dispatch(getCategories());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function updateCategory(categoryId, params) {
  console.log('params', params);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/categories/${categoryId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);

      dispatch(slice.actions.updateCategorySuccess(response.data.data));
      dispatch(getCategories());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCategory(categoryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await getValidToken(dispatch);
      const url = `${urls.user}/categories/${categoryId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(slice.actions.deleteCategorySuccess(categoryId));
      dispatch(getCategories());
    } catch (error) {
      dispatch(setError(errorHandle(error)));
    }
  };
}

// ----------------------------------------------------------------------
