import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: null,
  isOpen: false
};

const errorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setOpen(state, action) {
      state.isOpen = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
      state.isOpen = true;
    },
    clearError(state) {
      state.error = null;
      state.isOpen = false;
    }
  }
});

export const { setOpen, setError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
