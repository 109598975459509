import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import errorReducer from './slices/errors';
import userReducer from './slices/user';
import accountReducer from './slices/account';
import tokenReducer from './slices/token';
import kpiReducer from './slices/kpi';
import calendarReducer from './slices/calendar';
import selfPlanReducer from './slices/selfPlan';
import userManageReducer from './slices/userManage';
import categoryReducer from './slices/category';
import reviewReducer from './slices/review';
import surveyReducer from './slices/survey';
import journalReducer from './slices/journal';
import boothReducer from './slices/booth';
import networkReducer from './slices/network';
import noticeReducer from './slices/notice';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['']
};

const rootReducer = combineReducers({
  errors: errorReducer,
  account: accountReducer,
  user: userReducer,
  token: tokenReducer,
  kpi: kpiReducer,
  calendar: calendarReducer,
  userManage: userManageReducer,
  selfPlan: selfPlanReducer,
  category: categoryReducer,
  review: reviewReducer,
  journal: journalReducer,
  survey: surveyReducer,
  booth: boothReducer,
  network: networkReducer,
  notice: noticeReducer
});

export { rootPersistConfig, rootReducer };
