// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';
// components
// import Settings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ErrorDialogs from './pages/components-overview/material-ui/dialog/ErrorDialogs';
import { useDispatch, useSelector } from 'src/redux/store';
import { setOpen, clearError } from './redux/slices/errors';

export default function App() {
  const dispatch = useDispatch();
  const { isInitialized } = useAuth();
  const { error, isOpen } = useSelector((state) => state.errors);

  const onCloseError = () => {
    dispatch(setOpen(false));
    setTimeout(() => dispatch(clearError()), 500);
  };

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <NotistackProvider>
          {/* <Settings /> */}
          <ScrollToTop />
          <GoogleAnalytics />
          <ErrorDialogs open={isOpen} error={error} onClose={onCloseError} />
          {isInitialized ? <Router /> : <LoadingScreen />}
        </NotistackProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
